<template>
  <div class="venue">
    <h3 class="sub-title">Venue (Wuhan)</h3>
    <h3 class="venue-title">ASCVTS 2024 Venue: The Wuhan International Expo Center, Wuhan</h3>
    <div class="map">
      <p>
        <a-map name=""></a-map>
      </p>
    </div>
    <div class="table-responsive">
      <div class="table-tr">
        <div class="lt"><span>Address</span></div>
        <div class="rt">
          <span>619 parrot avenue, hanyang district, Wuhan, 430000, China</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Tel.</span></div>
        <div class="rt">
          <span>+86-027-86655088</span>
        </div>
      </div>
      <div class="table-tr">
        <div class="lt"><span>Website</span></div>
        <div class="rt">
          <span><a href="http://www.wniecm.com/" target="_blank">http://www.wniecm.com/</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "@/components/Map";
export default {

  components: {
    AMap
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.venue {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .venue-title {
    font-weight: 900;
    font-size: 20px;
    padding-left: 8px;
    border-left: 3px solid #19317e;
  }
  .map{
    width: 100%;
    margin: 20px 0px;
    img {
      max-width: 100%;
    }
  }
  .table-responsive {
    border-top: 3px solid #19317e;
    .table-tr {
      display: flex;
      font-size: 16px;
      .lt {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 20%;
        background-color: #f8f9fa;
        padding: 13px;
        border: 1px solid #dee2e6;
        border-top: none;
      }
      .rt {
        flex: 0 0 80%;
        font-family: "Akrobat Regular";
        border-bottom: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        span {
          display: block;
          padding: 13px;
          & + span {
            border-top: 1px solid #dee2e6;
          }
          &.subTitle {
            font-family: "Akrobat black";
            background: linear-gradient(73deg, #bc395c 20%, #3e437d 100%);
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            position: relative;
            font-style: italic;
          }
        }
        &>div {
          display: flex;
          border-bottom: 1px solid #dee2e6;
          .r-lt {
            padding: 13px;
            flex: 0 0 60%;
            border-right: 1px solid #dee2e6;
          }
          .r-rt {
            padding: 13px;
            flex: 0 0 40%;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
/deep/ .header-title {display: none}
</style>
