<template>
  <div id="container"></div>
</template>


<script>
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "0d2f9d04e5400da34cce3632352e1881",
};

export default {
  name: "Amap",
  data() {
    return {

    }
  },
  mounted() {
    this.initMap();
  },
  methods:{
    initMap(){
      AMapLoader.load({
        key:"985cab1d562e8f9f1ea42778fd7010a4",   // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:[],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          zoom:15,           //初始化地图级别
          lang: 'zh_cn',
          resizeEnable: true,
          center:[114.241734,30.506907], //初始化地图中心点位置
        });
        this.map.add(new AMap.Marker({
          position: this.map.getCenter()
        }));
      }).catch(e=>{
        console.log(e);
      })
    },
  }
}
</script>

<style lang="less" scoped>
#container{
  padding:0px;
  margin: 0px;
  width: 100%;
  height: 400px;
}
/deep/ .amap-logo {
  display: none !important;
  img {
    display: none !important;
  }
}
/deep/ .amap-copyright{
  display: none !important;
}
</style>
